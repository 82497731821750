import styled, {keyframes} from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Tags = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 400px;
  width: 100%;
  margin-left: 80px;
  margin-right: 80px;

  @media (max-width: 768px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  & > div {
    border: 1px solid ${({theme}) => theme.palatte.text.default};
    background-color: ${({theme}) => theme.palatte.background.contrast};
    padding: 8px 16px;
    margin-right: 8px;
    font-size: 14px;
  }
`;

export const ServiceHeader = styled.div`
  padding: 120px 20px 20px 20px;
  margin-bottom: 100px;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Thicccboi, sans-serif;
  background-color: ${({theme}) => theme.palatte.background.contrast};

  & > h1 {
    font-size: 6rem;
    line-height: 1.6;

    & > span {
      height: 86px;
      background-color: ${({theme}) => theme.palatte.text.default};
      padding: 1rem 2rem;
      color: #fff;
    }
    & > span:nth-child(2) {
      background-color: ${({theme}) => theme.palatte.background.highlight};
    }
  }

  @media (max-width: 1024px) {
    & > h1 {
      font-size: 5rem;
      line-height: 1.5;
    }
    & > span {
      height: 72px;
      padding: 1rem 1.6rem;
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 3rem;
      line-height: 1.7;
    }
    & > span {
    }
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const Section = styled.div`
  max-width: 1000px;
  margin: 64px 0px;
  height: 600px;
  // display: flex;
  justify-content: center;
  width: 50%;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SectionImage = styled.div`
  width: 500px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 440px;
    height: 320px;
    // background-color: ${({theme, color}) => theme.palatte.colors[color]};

    & > img {
      object-fit: cover;
      height: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    margin: 24px;
  }
`;

export const SectionDescription = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 80px;
  margin-right: 80px;
  color: ${({theme, color}) => theme.palatte.colors[color]};

  h2 {
    font-weight: 600;
    font-family: Thicccboi, sans-serif;
  }

  p {
    margin-top: 0px;
    line-height: 1.5;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100vw - 48px);
  }
`;

export const MoreInfo = styled.a`
  text-align: right;
  margin-right: 32px;
  margin-bottom: 32px;
  align-items: center;
  display: flex;
  align-self: flex-end;
  text-decoration: none;
  color: ${({theme, color}) => theme.palatte.colors[color]};

  & > div {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    background-color: ${({theme, color}) => theme.palatte.colors[color]};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SingleClient = styled.div`
  padding: 80px 16px;
  max-width: 1280px;
  margin: 80px auto 0px auto;

  & > h1 {
    font-size: 84px;
    font-family: Thicccboi;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 64px;
    }
  }
`;

export const ClientSection = styled.div`
  flex-direction: column;
  // align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px auto;

  .client-image {
    align-items: center;
    display: flex;
    justify-content: center;

    & > img {
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .client-wrap {
    & > div {
      background: #83abf5;
      border-radius: 24px;
      max-width: 480px;

      & > img {
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  & > div {
    & > img {
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  .desc-highlight {
    & > h1 {
      font-size: 84px;
      margin: 0px;
      margin-left: -80px;
      font-weight: 600;

      @media (max-width: 600px) {
        margin-left: 0px !important;
        font-size: 48px;
      }
    }

    & > p {
      line-height: 1.2;
    }

    background: #dbdbdb;
    font-size: 20px;
    max-width: 400px;
    padding: 32px;
  }
  .desc {
    font-size: 20px;
    max-width: 440px;
  }
`;
